
    .role-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .role-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .el-link + .el-link {
            margin-left: 10px;
        }
        /deep/ .el-textarea .el-textarea__inner {
            height: 84px;
            resize: none;
        }
        .el-checkbox-group {
            margin-left: 22px;
        }
        /deep/ .el-tree-node.is-expanded>.el-tree-node__children {
            display: flex;
            flex-wrap: wrap;
        }
        .permits-item {
            height: 100%;
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .el-form-item {
            margin-bottom: 0;
        }
        /deep/ .el-dialog {
            height: 440px;
            min-height: 440px;
            max-height: 440px;
            .el-dialog__body {
                height: 260px;
            }
        }
    }
